import React  from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// import PageAccounts from '../Pages/Publics/PageAccounts';


const PagePrivacyPolicy = React.lazy(() => import('../Pages/Legacy/PagePrivacyPolicy'));
const PageTermsConditions = React.lazy(() => import('../Pages/Legacy/PageTermsConditions'));
const PageInkaclubProgramme = React.lazy(() => import('../Pages/Legacy/PageInkaclubProgramme'));
const PageAccounts = React.lazy(() => import('../Pages/Publics/PageAccounts'));
const PageBabyRegister = React.lazy(() => import('../Pages/Publics/PageBabyRegister'));
const PageLogin = React.lazy(() => import('../Pages/Publics/PageLogin'));
const PageMain = React.lazy(() => import('../Pages/Publics/PageMain'));
const PageParentRegister = React.lazy(() => import('../Pages/Publics/PageParentRegister'));
const PagePasswordNew = React.lazy(() => import('../Pages/Publics/PagePasswordNew'));
const PagePasswordRecovery = React.lazy(() => import('../Pages/Publics/PagePasswordRecovery'));
const PageVerifyEmail = React.lazy(() => import('../Pages/Publics/PageVerifyEmail'));
const PageRecoveryConfirm = React.lazy(() => import('../Pages/Publics/PageRecoveryConfirm'));

export const Publics = () => {
  return (
    <React.Suspense fallback={<div className="loader"><h1>Cargando..</h1></div>}>
    <Switch>
      <Route exact path="/" component={PageMain} />
      <Route path="/code/:code" component={PageMain} />
      <Route path="/cuentas/:data" component={PageAccounts} />
      <Route path="/cuentas" component={PageAccounts} />

      <Route exact path="/registro-paso1" component={PageParentRegister} />
      <Route exact path="/registro-paso2" component={PageBabyRegister} />
      <Route
        exact
        path="/terminos-condiciones"
        component={PageTermsConditions}
      />
      <Route exact path="/politica-privacidad" component={PagePrivacyPolicy} />
      <Route
        exact
        path="/programa-inkaclub"
        component={PageInkaclubProgramme}
      />
      <Route exact path="/iniciar-sesion" component={PageLogin} />
      <Route exact path="/recuperar-cuenta" component={PagePasswordRecovery} />
      <Route
        exact
        path="/nueva-contrasena/:token"
        component={PagePasswordNew}
      />
      <Route exact path="/contrasena-verificada" component={PageVerifyEmail} />

      <Route
        exact
        path="/contraseña-recuperada"
        component={PageRecoveryConfirm}
      />

      <Redirect to="/" />
    </Switch>
    </React.Suspense>
  );
};
